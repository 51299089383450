.container{
    margin-left: 0px;
}

.physique{
    background-color: lightcoral;
    width: 2050px;
}
.aquatique{
    background-color: lightblue;
    width: 1100px;
}
.titre-col{
border-style: solid;
border-width: 1px;
    width: 30px;
}
.suivi-col{
border-style: solid;
border-width: 1px;
    width: 100px;
}
.sous_cat_0{
    width: 50px;
    background-color: #89dff3;
}
.sous_cat_1{
    width: 50px;
    background-color: #89dff3;
}
.sous_cat_2{
    width: 50px;
    background-color: #e8cd7a;
}
.sous_cat_3{
    width: 50px;
    background-color: #f3a789;
}
.sous_cat_4{
    width: 50px;
    background-color: #becccf;
}
.sous_cat_5{
    width: 50px;
    background-color: #44b540;
}
.suivi-table{
    width:  3950px;
    border-style: solid;
}
.suivi-date{
    width: 120px;
}
.suivi-nom{
    width: 150px;
}